import { createRouter, createWebHistory } from "vue-router";
import Inicio from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio,
    title: "Nacozari de Garcia",
  },
  {
    path: "/quienes-somos",
    name: "Quienes-somos",
    title: "Quienes somos",
    component: () =>
      import(
        /* webpackChunkName: "quienes-somos" */ "../views/Quienes-somos.vue"
      ),
  },

  {
    path: "/actividades",
    name: "Actividades",
    title: "Actividades",
    component: () =>
      import(/* webpackChunkName: "actividades" */ "../views/Actividades.vue"),
  },

  {
    path: "/efemerides",
    name: "Efemerides",
    title: "Efemerides",
    component: () =>
      import(/* webpackChunkName: "efemerides" */ "../views/Efemerides.vue"),
  },

  {
    //path: "/post/:id",
    path: "/blog/:slug",
    name: "Blog",
    title: "Blogs",
    //props: true,
    component: () => import(/* webpackChunkName: "post" */ "../views/Post.vue"),
  },


  {
    path: "/personajes",
    name: "Personajes",
    title: "Personajes",
    component: () =>
      import(/* webpackChunkName: "personajes" */ "../views/Personajes.vue"),
  },

  {
    path: "/lugares",
    name: "Lugares",
    title: "Lugares",
    component: () =>
      import(/* webpackChunkName: "lugares" */ "../views/Lugares.vue"),
  },
  {
    path: "/cronicas",
    name: "Cronicas",
    title: "Crónicas y microhistorias",
    component: () =>
      import(/* webpackChunkName: "cronicas" */ "../views/Cronicas.vue"),
  },
  {
    path: "/donar",
    name: "Donar",
    title: "Donar",
    component: () =>
      import(/* webpackChunkName: "contacto" */ "../views/Contacto.vue"),
  },
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  },
});

router.beforeEach((to, from, next) => {
  //console.log(to);

  let documentTitle = `${process.env.VUE_APP_TITLE} - ${to.name}`;

  if (to.params.title) {
    documentTitle += `- ${to.params.title}`;
  }
  document.title = documentTitle;
  next();
});






export default router;
