<template>
  <transition
    name="fade"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div v-if="show" class="preloader">
      <div id="loading-wrapper">
        <div class="loader">Cargando...</div>
      </div>
    </div>
  </transition>
</template>

<style lang="stylus">
#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(#786a64, 0.8);
  z-index 999
  display flex
  flex 1 1 auto
  justify-content center
  align-items center
  align-content center
  flex-direction column
  backdrop-filter: blur(5px);

}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 1rem;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  name: "loading-screen",

  data() {
    return {
      show: true,
    };
  },

  created() {
    //console.log(this.show);
  },

  mounted() {
    document.querySelector(".preloader").classList.add("animated", "fadeOut");
    this.showToggle();
    this.show = false;
    //console.log(this.show);
  },

  methods: {
    showToggle() {
      setTimeout(() => {
        this.show = false;
      }, 500);
    },
  },
};
</script>
