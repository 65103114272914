import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";


// Vue.use(VueGtag, {
// 	config: {
// 		id: "G-YWJBMD7PJ6", params: {
// 			send_page_view: true
// 		}
// 	}
// });
//import "vue-glide-js/dist/vue-glide.css";
import "./css/styles.styl";
createApp(App)
	.use(store)
	.use(router)
	.use(VueGtag, {
		config: {
			id: "G-YWJBMD7PJ6",
			params: {
				send_page_view: true,
			},
		},
	})
	.mount("#app");
