<template>
  <main>
    <Loading></Loading>
    <!-- inicia slider -->
    <div class="slider">
      <h1 class="parallax__jalar">
        <span class="animate__animated animate__slideInUp" data-wow-offset="0"
          >Historia de Nacozari de garcía</span
        >
      </h1>
      <div class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <li
              class="glide__slide"
              v-for="slide in slides"
              v-bind:key="slide.id"
            >
              <img
                :src="require(`@/assets/images/slider/home/${slide.imagen}`)"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- termina slider -->

    <section>
      <div class="blockquote_box">
        <blockquote
          class="wow animate__slideInUp"
          data-wow-offset="150"
          data-wow-delay=".5s"
        >
          Preservando y difundiendo nuestro legado histórico
        </blockquote>
      </div>
    </section>

    <section class="somos wow animate__fadeIn">
      <p class="parallax__opacity">
        Somos una asociación civil dedicada al estudio, investigación, rescate y
        promoción de la crónica y microhistoria del municipio de Nacozari de
        García, Sonora.
      </p>
    </section>

    <section>
      <div class="home-list">
        <ul>
          <li class="wow animate__fadeInLeft">
            <router-link to="/efemerides">
              <figure>
                <img
                  src="@/assets/images/content/icons-home/efemerides.svg"
                  alt="Efemerides"
                  width="100%"
                  height="auto"
                />
              </figure>
              <figcaption>Efemérides</figcaption>
            </router-link>
          </li>

          <li class="wow animate__fadeInLeft" data-wow-delay=".7s">
            <router-link to="/personajes">
              <figure>
                <img
                  src="@/assets/images/content/icons-home/personaje.svg"
                  alt="Personajes ilustres"
                  width="100%"
                  height="auto"
                />
              </figure>
              <figcaption>Personajes ilustres</figcaption>
            </router-link>
          </li>

          <li class="wow animate__fadeInLeft" data-wow-delay="1.2s">
            <router-link to="/cronicas">
              <figure>
                <img
                  src="@/assets/images/content/icons-home/cronica.svg"
                  alt="Crónica y microhistoria"
                  width="100%"
                  height="auto"
                />
              </figure>
              <figcaption>Crónica y microhistoria</figcaption>
            </router-link>
          </li>

          <li class="wow animate__fadeInLeft" data-wow-delay="1.8s">
            <router-link to="/lugares">
              <figure>
                <img
                  src="@/assets/images/content/icons-home/lugar.svg"
                  alt="Sitios y lugares históricos"
                  width="100%"
                  height="auto"
                />
              </figure>
              <figcaption>Sitios y lugares históricos</figcaption>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section>
      <div class="banner">
        <img
          src="@/assets/images/content/graphic-tren.jpg"
          width="100%"
          height="auto"
          class="wow animate__kenBurns"
          data-wow-duration="6s"
        />
      </div>

      <div class="contact" id="contacto">
        <div class="main-container grid_2 gap-4">
          <div
            class="contact-form wow animate__fadeInUp"
            data-wow-duration="2s"
            data-wow-offset="-200"
          >
            <form onsubmit="return false">
              <div class="fieldset">
                <label for="nombre">Nombre completo:</label>
                <input
                  placeholder="Nombre"
                  id="nombre"
                  name="nombre"
                  type="text"
                  required
                />
              </div>

              <div class="fieldset">
                <label for="nombre">Asunto:</label>
                <input
                  placeholder="Asunto"
                  id="asunto"
                  name="asunto"
                  type="text"
                />
              </div>

              <div class="fieldset">
                <label for="email">Correo electrónico:</label>
                <input
                  placeholder="Correo electrónico"
                  id="email"
                  name="email"
                  type="email"
                  required
                />
              </div>

              <div class="fieldset">
                <label for="phone">Teléfono:</label>
                <input
                  placeholder="Teléfono"
                  id="phone"
                  name="phone"
                  type="tel"
                />
              </div>

              <div class="fieldset">
                <label for="mensaje">Mensaje:</label>
                <textarea
                  placeholder="Mensaje"
                  id="mensaje"
                  name="mensaje"
                  required
                ></textarea>
              </div>

              <button id="submit" class="btn-primary" type="button">
                ENVIAR
              </button>
            </form>
          </div>
          <div class="contact-data wow animate__fadeIn">
            <h4>CONTACTO</h4>

            <p aria-label="icon-email" class="wow animate__fadeIn">
              <a target="_blank" href="mailto:contacto@historiadenacozari.org"
                >contacto@historiadenacozari.org</a
              >
            </p>

            <p aria-label="icon-cp" class="wow animate__fadeIn">
              Apartado Postal 14<br />
              84340 Nacozari de García, Sonora
            </p>

            <p aria-label="icon-direccion" class="wow animate__fadeIn">
              Av. Rosales 123 <br />
              Col. Centro <br />
              83000 Hermosillo, Son.
            </p>

            <div aria-label="icon-telefono" class="wow animate__fadeIn">
              <a target="_blank" href="tel:+526341061707">(634) 106 1707</a>
            </div>

            <div class="suscribe">
              <!-- Begin Mailchimp Signup Form -->
              <div id="mc_embed_signup">
                <form
                  action="https://historiadenacozari.us1.list-manage.com/subscribe/post?u=394646850ff3488606fd026cd&amp;id=84e1129ac8"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="validate"
                  target="_blank"
                  novalidate
                >
                  <div id="mc_embed_signup_scroll">
                    <label for="mce-EMAIL"
                      >Suscríbete para recibir noticias
                    </label>
                    <input
                      type="email"
                      value=""
                      name="EMAIL"
                      class="email"
                      id="mce-EMAIL"
                      placeholder="correo electrónico"
                      required
                    />
                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                    <div
                      style="position: absolute; left: -5000px"
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_394646850ff3488606fd026cd_84e1129ac8"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <div class="clear">
                      <input
                        type="submit"
                        value="Subscribir"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                      />
                    </div>
                  </div>
                </form>
              </div>

              <!--End mc_embed_signup-->
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style>
@import "../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
</style>

<style lang="stylus" scoped>

.somos
	background url('../assets/images/content/graphic-home-2.jpg') no-repeat center top
	background-size cover
	background-attachment fixed
	width 100%
	//min-height 658px
	min-height 420px
	display flex
	flex-direction column
	justify-content center
	color white

	p
		width 98%
		max-width 720px
		color inherit
		align-self center
		font-family: var(--domine);
		font-size: 2rem;
		font-weight: bold;
		font-stretch: normal;
		line-height: 1.35;
		letter-spacing: 0.74px;
		text-align: center
		@media only screen and (max-width 64rem)
			font-size 1.5rem
			padding-top 4rem

.home-list
	background var(--brownish-grey)
	color white
	text-transform uppercase
	margin 2rem auto

	a
		color inherit
		text-decoration none
		font inherit
		text-align center
		display block
		transition all 0.3s ease-in-out

		&:hover
			opacity .8

		&:active
			transform scale(.98)

	figure
		padding 0
		margin 0
		display block

		img
			margin auto
			width 98%
			max-width 95px
			height auto
			min-block-size: 8rem;
			display block

	figcaption
		padding 1rem 0

	ul
		width 98%
		max-width 1228px
		margin auto
		list-style none
		padding 0
		margin 0 auto
		display flex
		flex-direction row
		justify-content space-around
		gap 1rem

		@media only screen and (max-width 64rem)
			flex-direction column
			align-items center
			justify-content center

		li
			text-align center
			max-width 135px
			padding 1rem 0
			font-family: var(--varta);
			font-size 1.03rem
			display flex
			flex-direction column
			justify-content space-around
			gap 1rem

			@media only screen and (max-width 64rem)
				flex-direction row
				max-width none
				align-items: center
				justify-content: flex-start
				font-size 1.2rem
				gap 2rem
				padding 2rem
				text-align left

.contact-data
	text-align left
	font-size 0.906rem
	color var(--reddish);
	@media only screen and (max-width 64rem)
		padding-left 2rem

	a
		color inherit
		font-size inherit
		font-weight bold
		text-decoration none
		border-bottom 1px solid
		width max-content

		&::hover
			color var(--brownish-grey)!important

	h4
		font-family : var(--domine)
		font-size 1.375rem
		text-align center

.banner
	overflow hidden
	width 100%

//Glide js fade transition
.glide__slides {transform:none !important; width:auto !important; display:block;}
.glide__slide {position:absolute; left:0; top:0; opacity:0; transition:opacity 1s;}
.glide__slide:first-child {position:relative;}
.glide__slide--active {z-index:1; opacity:1;}




.parallax__jalar {
	//zoom: var(--zoom);
	transform: translateY(var(--translateY))
	will-change: translateY;
}

.parallax__opacity {
	opacity: var(--opacity)
	padding-bottom: var(--lineHeight)
	will-change: auto;
}
</style>

<style>
/* MailChimp Form Embed Code - Slim - 12/15/2015 v10.7 */
#mc_embed_signup {
  background: #fff;
  clear: left;
  font-size: 1rem;
}

#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  padding: 10px 0 10px 3%;
}
#mc_embed_signup h2 {
  font-weight: bold;
  padding: 0;
  margin: 15px 0;
  font-size: 1.4em;
}
#mc_embed_signup input {
  border: 1px solid #999;
  -webkit-appearance: none;
}
#mc_embed_signup input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
#mc_embed_signup input[type="radio"] {
  -webkit-appearance: radio;
}
#mc_embed_signup input:focus {
  border-color: #333;
}
#mc_embed_signup .button {
  clear: both;
  background-color: #aaa;
  border: 0 none;
  border-radius: 4px;
  letter-spacing: 0.03em;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-size: 15px;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
  transition: all 0.23s ease-in-out 0s;
}
#mc_embed_signup .button:hover {
  background-color: #777;
}
#mc_embed_signup .small-meta {
  font-size: 11px;
}
#mc_embed_signup .nowrap {
  white-space: nowrap;
}
#mc_embed_signup .clear {
  clear: none;
  display: inline;
}

#mc_embed_signup label {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
}
#mc_embed_signup input.email {
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, Verdana,
    sans-serif;
  font-size: 15px;
  display: block;
  padding: 0 0.4em;
  margin: 0 4% 10px 0;
  min-height: 32px;
  width: 58%;
  min-width: 130px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
#mc_embed_signup input.button {
  display: block;
  width: 35%;
  margin: 0 0 10px 0;
  min-width: 90px;
}

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em 0.5em 0em 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}
#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}
#mc_embed_signup #mce-error-response {
  display: none;
}
#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}
#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0;
}
</style>

<script>
import Loading from "@/components/Loading.vue";
import Glide from "@glidejs/glide";
import * as basicScroll from "basicscroll";
import { WOW } from "wowjs";

//import Email from "smtpjs";

export default {
  name: "home",
  components: {
    Loading,
    // "tiny-slider": VueTinySlider
  },

  data: function () {
    return {
      slides: [
        {
          id: 0,
          imagen: "banner1.jpg",
        },
        {
          id: 1,
          imagen: "banner2.jpg",
        },
        {
          id: 2,
          imagen: "banner3.jpg",
        },
      ],

      // homeSliderOptions: {
      //   autoplay: true,
      //   autoplayTimeout: 3000,
      //   mouseDrag: true,
      //   nav: false,
      //   loop: true,
      //   items: 1,
      //   arrowKeys: true,
      //   controls: false,
      //   gutter: 30,
      //   edgePadding: 0,
      //   center: true,
      //   autoplayButton: false
      // }
    };
  },

  mounted() {
    this.homeSlider();
    this.parallaxMe();

    //this.enviar();

    this.$nextTick(() => {
      // After the dom is rendered, execute the animation
      const wow = new WOW({
        boxClass: "wow", // default
        animateClass: "animate__animated", // default
        offset: 200,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },

  methods: {
    homeSlider() {
      const glide = new Glide(".glide", {
        type: "carousel",
        startAt: 0,
        perView: 1,
        gap: 0,
        hoverpause: true,
        autoplay: 2500,
      });

      glide.mount();
    },

    parallaxMe() {
      const parallaxJalar = basicScroll.create({
        elem: document.querySelector(".parallax__jalar"),
        from: "top-bottom",
        to: "middle-top",
        //track: false,
        props: {
          "--translateY": {
            from: "100%",
            to: "-60%",
          },
        },
      });

      const parallaxOpacity = basicScroll.create({
        elem: document.querySelector(".parallax__opacity"),
        from: "top-bottom",
        to: "middle-top",
        props: {
          "--opacity": {
            from: "0%",
            to: "100%",
          },
          "--lineHeight": {
            from: "20rem",
            to: "1rem",
          },
        },
      });

      parallaxJalar.start();
      parallaxOpacity.start();
    },
  },
};
</script>
