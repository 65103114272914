<template>
  <main>
    <header class="site-header">
      <div class="main-container">
        <div class="logo animate__animated animate__slideInDown">
          <router-link to="/"
            ><img
              src="@/assets/images/brand/logo.png"
              alt="Historia de Nacozari"
          /></router-link>
        </div>
        <button class="mobile-menu">☰</button>
        <nav class="main-navigation">
          <ul>
            <li>
              <router-link @click="closeNavigation" to="/">Inicio</router-link>
            </li>
            <li>
              <router-link @click="closeNavigation" to="/quienes-somos"
                >¿Quiénes Somos?</router-link
              >
              <ul>
                <li>
                  <router-link
                    @click="closeNavigation"
                    :to="{ path: '/quienes-somos', hash: '#mision' }"
                  >
                    Misión y Visión
                  </router-link>
                </li>

                <li>
                  <router-link
                    @click="closeNavigation"
                    :to="{
                      path: '/quienes-somos',
                      hash: '#consejo-consultivo',
                    }"
                    >Consejo consultivo</router-link
                  >
                </li>
                <li>
                  <router-link
                    @click="closeNavigation"
                    :to="{ path: '/quienes-somos', hash: '#presidente' }"
                    >Presidente</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link @click="closeNavigation" to="/actividades"
                >Actividades</router-link
              >
            </li>

            <li>
              <router-link @click="closeNavigation" to="/efemerides"
                >Efemérides</router-link
              >
            </li>
            <li>
              <router-link @click="closeNavigation" to="/personajes"
                >Personajes</router-link
              >
            </li>
            <li>
              <router-link @click="closeNavigation" to="/cronicas"
                >crónica y microhistoria</router-link
              >
            </li>
            <li>
              <router-link @click="closeNavigation" to="/lugares"
                >Lugares</router-link
              >
            </li>
            <li>
              <router-link
                @click="closeNavigation"
                :to="{ path: '/', hash: '#contacto' }"
                >Contacto</router-link
              >
            </li>
            <!-- https://donorbox.org/con-su-donativo-seguiremos-rescatando-difundiendo-nuestra-historia -->
            <li class="boton-donar">
              <router-link @click="closeNavigation" to="/donar"
                >Donar</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <router-view></router-view>

    <footer class="site-footer">
      <div class="main-container">
        <ul class="socialicons">
          <li class="icon-facebook">
            <a
              target="_blank"
              href="https://web.facebook.com/HistoriaDeNacozari"
              >Facebook Historia de Nacozari</a
            >
          </li>
          <li class="icon-twitter">
            <a target="" href="https://twitter.com/HNG_AC"
              >Twitter Historia de Nacozari</a
            >
          </li>
        </ul>
      </div>
    </footer>
  </main>
</template>

<style lang="stylus">
:root {
  --medium-brown: #845212;
  --brownish-grey: #786a64;
  --reddish: #c05036;
  --domine: 'Domine', serif;
  --varta: 'Varta', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&family=Varta:wght@300;400;500;600;700&display=swap');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  min-height: 100vh;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
</style>

<script>
//const mediaQueryList = window.matchMedia("only screen and (max-width: 768px");

export default {
  methods: {
    mobileNavigation() {
      //if (mediaQueryList.matches) {
      if (window.matchMedia("(max-width: 768px)").matches) {
        //mobile menu
        document
          .querySelector(".mobile-menu")
          .addEventListener("click", function () {
            document.querySelector(".mobile-menu").classList.toggle("open");
            document.querySelector(".main-navigation").classList.toggle("open");
          });
      }
    },

    closeNavigation() {
      if (window.matchMedia("(max-width: 768px)").matches) {
        //if (mediaQueryList.matches) {
        //console.log("hola");
        document.querySelector("nav.open").classList.remove("open");
        document.querySelector(".mobile-menu.open").classList.remove("open");
      }
    },
  },

  mounted() {
    this.mobileNavigation();
  },
};
</script>
